import { useEffect, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase, { firebaseConfig } from '@lib/firebase';
import { GQL_ENDPOINT } from '@lib/config';
import { print } from 'graphql';
import gql from 'graphql-tag';
import { useDebouncedCallback } from 'use-debounce';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { Modal, message, notification } from 'antd';

const { confirm } = Modal;

const GET_ID_TOKEN = gql`
  mutation($token: String!) {
    getSessionToken(token: $token) {
      token
    }
  }
`;

export const SESSION_TOKEN_KEY = 'session_token_v1';

export default function useAuth(autoRetrieve = true) {
  const [cookies, setCookie] = useCookies([SESSION_TOKEN_KEY]);
  const [user, loading, error] = useAuthState(firebase.auth());
  const sessionToken = cookies[SESSION_TOKEN_KEY];
  const [notifyError] = useDebouncedCallback(
    (message: string) => {
      notification.error({
        message
      });
    },
    5000,
    { leading: true }
  );

  const setSessionToken = token => {
    return setCookie(SESSION_TOKEN_KEY, token);
  };

  // const getSessionToken = async () => {
  //   if (user && !sessionToken) {
  //     await retrieveSessionToken(user);
  //     return cookies[SESSION_TOKEN_KEY];
  //   }
  //   return sessionToken;
  // };

  const deleteAuthCookies = () => {
    setSessionToken('');
  };

  const signOutUser = () => {
    firebase.auth().signOut();
    deleteAuthCookies();
  };

  const [retrieveSessionToken] = useDebouncedCallback(
    async (user_: any, callback?: Function) => {
      try {
        if (!user_) {
          return;
        }
        const token = await user_.getIdToken(true);
        let vars = JSON.stringify({
          query: print(GET_ID_TOKEN),
          variables: { token }
        });

        let res = await fetch(GQL_ENDPOINT, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: sessionToken ? `Bearer ${sessionToken}` : ''
          },
          body: vars
        });
        const data = await res.json();
        const token_ = data.data.getSessionToken.token;
        token_ && token_.length && (await setSessionToken(token_));
        if (!token_) {
          if (data?.errors) {
            // notifyError('Session expired. Logging you out');
            setTimeout(signOutUser, 1000);
          }
        }
      } catch (error) {
        console.warn('Fetching id token failed');
        console.error('ERROR', error);
        switch (error?.type) {
          case 'auth/123':
            break;
          default:
            break;
        }
      } finally {
        callback && callback();
      }
    },
    1000
  );

  useEffect(() => {
    if (autoRetrieve && user) {
      try {
        if (sessionToken && sessionToken.length) {
          const claims = jwt_decode(sessionToken);
          const now = Math.ceil(moment().subtract(4, 'days').valueOf() / 1000);
          const exp = claims?.exp || 0;
          if (now < exp) {
            console.log('Token still valid');
          } else {
            console.log('Retrieving new session token');
            retrieveSessionToken(user);
          }
        } else {
          retrieveSessionToken(user);
        }
      } catch (e) {
        retrieveSessionToken(user);
      }
    }
  }, [autoRetrieve, user, sessionToken, retrieveSessionToken]);

  return {
    user,
    retrieveSessionToken,
    // getSessionToken,
    setSessionToken,
    signOutUser,
    loading,
    error
  };
}
